<template>
  <div>
    <div class="pdfok" id="pdfok" v-if="pdfok"></div>
    <div id='printMe' style=" width: 21cm; margin: 0 auto;">
      <Keshi1 v-if='dataover' :info1='info'></Keshi1>
      <Keshi2 v-if='dataover1' :info1='info'></Keshi2>
    </div>

  </div>
</template>

<script>

import Keshi1 from './com/Keshi1.vue'
import Keshi2 from './com/Keshi2.vue'
export default {
  name: 'export',
  components: {
    Keshi1, Keshi2
  },
  data() {
    return {
      pdfok: false,
      dataover: true,
      dataover1: true,
      printObj: {
        id: 'printMe',
        popTitle: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      userid: '',
      cover: {},
      info: {},

    }
  },
  mounted() {
    // exportStuPdfSingle
    let id = this.getParams('id')
    let dataid = this.getParams('dataid')
    let pmid = this.getParams('pmid')
    this.getDataList(id, dataid, pmid)
  },
  methods: {
    //过滤特殊字符, 校验所有输入域是否含有特殊符号
    escape2Html(str) {
      var arrEntities = { 'lt': '<', 'gt': '>', 'nbsp': ' ', 'amp': '&', 'quot': '"', 'middot': '·', '↵': ' \n ' }
      return str.replace(/&(lt|gt|nbsp|amp|quot|middot);/ig, function (all, t) {
        return arrEntities[t]
      })
    },

    getDataList(id, dataid, pmid) {
      let _this = this
      this.$http.post('/api/export_stu_ks_manual', {
        stu_id: parseInt(id),
        manual_id: parseInt(dataid),
        pmid: parseInt(pmid)
      }).then(res => {
        if (res.data.manuals && res.data.manuals.length > 0 && res.data.manuals[0] && res.data.manuals[0].jbxx_id) {
          let rData = res.data.manuals[0]
          let num = 0
          if (rData.evaluation) {
            rData.evaluation = JSON.parse(rData.evaluation)
            let e = rData.evaluation
            let totalyd = parseInt(e.yd1) + parseInt(e.yd2) + parseInt(e.yd3) + parseInt(e.yd4) + parseInt(e.yd5) + parseInt(e.yd6) + parseInt(e.yd7) + parseInt(e.yd8) + parseInt(e.yd9) + parseInt(e.yd10)
            let totallc = parseInt(e.lc1) + parseInt(e.lc2) + parseInt(e.lc3) + parseInt(e.lc4) + parseInt(e.lc5) + parseInt(e.lc6) + parseInt(e.lc7) + parseInt(e.lc8) + parseInt(e.lc9) + parseInt(e.lc10)

            let arr = [parseInt(e.xmscore1), parseInt(e.xmscore2), +parseInt(e.xmscore3)]
            console.log(707070, parseInt('aa') == true)

            let xmscore1 = e.xmscore1, xmscore2 = e.xmscore2, xmscore3 = e.xmscore3
            if (!e.xmname1 || e.xmname1 == '无') { xmscore1 = 0 } else { num += 1 }
            if (!e.xmname2 || e.xmname2 == '无') { xmscore2 = 0 } else { num += 1 }
            if (!e.xmname3 || e.xmname3 == '无') { xmscore3 = 0 } else { num += 1 }
            let xmscorepj = 0
            if (num == 0) {
              xmscorepj = 0
            } else {
              let a = parseInt(xmscore1) ? parseInt(xmscore1) : 0
              let b = parseInt(xmscore2) ? parseInt(xmscore2) : 0
              let c = parseInt(xmscore3) ? parseInt(xmscore3) : 0
              xmscorepj = (a + b + c) / num
            }
            console.log(82, xmscorepj)
            xmscorepj = Number(xmscorepj.toFixed(2))
            rData.evaluation.totalyd = totalyd
            if (parseInt(rData.evaluation.kcScore) > 0) {
              rData.evaluation.finalScore = parseInt(totalyd) - parseInt(rData.evaluation.kcScore)
            } else {
              rData.evaluation.finalScore = parseInt(totalyd)
            }
            rData.evaluation.totallc = totallc
            rData.evaluation.xmscorepj = xmscorepj
            if (e.sign1) {
              rData.evaluation.sign1 = e.sign1.split('?')[0] + '?x-oss-process=image/resize,w_200'
            }
            if (e.sign2) {
              rData.evaluation.sign2 = e.sign2.split('?')[0] + '?x-oss-process=image/resize,w_200'
            }
          }
          if (rData.sign) {
            rData.sign = rData.sign.split('?')[0] + '?x-oss-process=image/resize,w_200'
          }

          if (rData.content) {
            rData.content = this.escape2Html(rData.content)
          }
          this.info = rData
        }
        // console.log(11111, this.info)
        this.$nextTick(() => {
          setTimeout(() => { _this.pdfok = true }, 500)
        })


      })

    },

    getParams(name) {
      let n_url = window.location.href
      n_url = n_url.replace('/#', '')
      if (n_url.indexOf('?') > 0) {
        n_url = n_url.split('?')[1]
      }
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      var r = n_url.match(reg)
      if (r != null) return unescape(r[2])
      return null
    },
    //分割数组 arr：原数组  num：分割后每个数组里的数据条数
    util_spliceArrByN(arr, num) {
      var _arr = []
      while (arr.length) {
        _arr.push(arr.splice(0, num))
      }
      return _arr
    },
    //数组按某属性分组
    groupBy(array, f) {
      let groups = {}
      array.forEach(function (o) {
        let group = JSON.stringify(f(o))
        groups[group] = groups[group] || []
        groups[group].push(o)
      })
      return Object.keys(groups).map(function (group) {
        return groups[group]
      })
    }
  }
}
</script>
<style lang="less" type="text/less">
@import "/public/css/pdf_default";
</style>